import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import { Button, Modal, PhoneInput, OtpInput } from "components";
import { RootState, useAppDispatch } from "store";
import {
  getUserDetail,
  resendPhoneVerification,
  updateUserProfile,
  verifyPhoneNumber
} from "store/users";
import { EditIcon } from "icons";
import { FormLabel } from "@mui/material";

interface MobileConfirmationProps {
  isOpen: boolean;
}

const defaultMobilePhone = {
  number: "",
  code: "",
  country: ""
};

export default function MobileConfirmation({
  isOpen
}: MobileConfirmationProps) {
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [mobilePhone, setMobilePhone] = useState(defaultMobilePhone);
  const { currentUser } = useSelector((state: RootState) => state.users);
  const [otp, setOtp] = useState<string>("");

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser) {
      setMobilePhone({
        number: currentUser.phone_number,
        code: currentUser.country_code,
        country: currentUser.country_code
      });
    }
  }, [currentUser]);

  const handleClickSendCode = () => {
    if (!currentUser) return;
    setIsLoading(true);
    dispatch(
      updateUserProfile({
        id: currentUser.id,
        data: {
          company_name: currentUser.company_name || "",
          position: currentUser.position || "",
          phone_number: mobilePhone.number,
          country_code: mobilePhone.country
        }
      })
    )
      .unwrap()
      .then(() => {
        dispatch(resendPhoneVerification({ email: currentUser.email }))
          .unwrap()
          .then(() => {
            setIsLoading(false);
            setIsCodeSent(true);
            setIsEditing(false);
            dispatch(getUserDetail());
          });
      });
  };

  const handleClickVerifyCode = () => {
    if (!currentUser) return;
    setIsLoading(true);
    dispatch(verifyPhoneNumber({ email: currentUser.email, code: otp }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        dispatch(getUserDetail());
      });
  };

  const handleChangeMobilePhone = (phone: {
    number: string;
    code: string;
    country: string;
  }) => {
    setMobilePhone({ ...phone });
  };

  const handleChangeOtp = (value: string) => {
    setOtp(value);
  };

  return (
    <Modal
      open={isOpen}
      title={t("app:mobile-confirmation.popup-title")}
      isClosable={false}
    >
      <div className="px-4 pb-4">
        {isEditing ? (
          <PhoneInput
            label={t("auth:register.mobile-phone")}
            value={mobilePhone.number}
            onChange={handleChangeMobilePhone}
          />
        ) : (
          <div className="flex items-end justify-between">
            <div className="flex flex-col">
              <FormLabel>{t("auth:register.mobile-phone")}</FormLabel>
              {mobilePhone.number}
            </div>
            <IconButton>
              <EditIcon onClick={() => setIsEditing(true)} />
            </IconButton>
          </div>
        )}

        <p className="text-sm mt-4">
          {isCodeSent ? (
            <>
              {t("app:mobile-confirmation.code-sent-message")}

              <span
                className="text-primary ml-1 font-semibold cursor-pointer"
                onClick={handleClickSendCode}
              >
                {t("common:button.resend-code")}
              </span>
            </>
          ) : (
            <>{t("app:mobile-confirmation.verify-message")}</>
          )}
        </p>
      </div>

      {isCodeSent && (
        <>
          <p className="text-sm mb-3 text-main-black text-center font-semibold">
            {t("app:mobile-confirmation.enter-code")}
          </p>
          <div className="w-60 mx-auto">
            <OtpInput value={otp} onChange={handleChangeOtp} />
          </div>
        </>
      )}
      <div className="mt-4 flex justify-end p-4 border-t border-gray-da">
        <Button
          loading={isLoading}
          disableElevation
          variant="contained"
          onClick={isCodeSent ? handleClickVerifyCode : handleClickSendCode}
        >
          {isCodeSent
            ? t("common:button.confirm")
            : t("common:button.send-code")}
        </Button>
      </div>
    </Modal>
  );
}
