import {
  AiModelType,
  IAIPrompt,
  IChatHistoryResponse
} from "types/ai-assistant.model";

import { authorizedRequest } from "./request";

const getSuggestivePromptList = (
  id: number,
  aiModel?: AiModelType | string
): Promise<string[]> =>
  authorizedRequest.get(`api/analyzes/analysis/${id}/prompt-options`, {
    params: { ai_model: aiModel === "" ? undefined : aiModel }
  });

const getChatHistory = (
  versionId: number,
  offset?: number,
  limit?: number,
  aiModel?: AiModelType
): Promise<IChatHistoryResponse> =>
  authorizedRequest.get(`/api/data-sources/ai-chat-history/`, {
    params: {
      limit: limit ?? 10,
      offset: offset ?? 0,
      version: versionId,
      ai_model: aiModel
    }
  });

const editUserPrompt = (
  prompt: string,
  historyId: number
): Promise<{ answer: string }> =>
  authorizedRequest.post("/api/data-sources/ai-chat/regenerate-prompt/ ", {
    prompt,
    history: historyId
  });

const sendPrompt = (
  prompt: string,
  versionId: number,
  aiModel?: AiModelType | string
): Promise<{ answer: string; history_id: number }> =>
  authorizedRequest.post("/api/data-sources/ai-chat/", {
    prompt,
    version: versionId,
    is_testing: false,
    ai_model: aiModel
  });

const getAIPromptOptions = (): Promise<IAIPrompt[]> =>
  authorizedRequest.get("api/data-sources/ai-prompt-options/");

const deletePrompt = (history: number): Promise<void> =>
  authorizedRequest.delete(`/api/data-sources/ai-chat-history/${history}/`);

const aiAssistantServices = {
  getSuggestivePromptList,
  getChatHistory,
  editUserPrompt,
  sendPrompt,
  deletePrompt,
  getAIPromptOptions
};

export default aiAssistantServices;
