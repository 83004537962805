import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";
import { IPlan, ISubscription } from "types/subscription.model";

interface InitialState {
  loading: boolean;
  subscription: ISubscription;
  plans: IPlan[];
  analysisAvailable: number;
  tickerRequestsAvailable: number;
  paymentFrequency: string;
}

const initialState: InitialState = {
  loading: true,
  subscription: {
    product: {
      id: 0,
      name: "",
      configs: {
        analyzes: {
          amount: 0,
          updates: ""
        },
        versions_per_analysis: {
          amount: 0,
          updates: ""
        },
        charts_per_analysis: {
          amount: 0,
          updates: ""
        },
        ticker_requests: {
          amount: 0,
          updates: ""
        },
        comparable_company: {
          amount: 0,
          updates: ""
        }
      },
      stock_symbol_requests: 0,
      product_price: 0,
      trial_period: 0,
      versions_per_analysis: 0,
      ai_chat_request: 0,
      analysis_version_chart: 0,
      analysis_available: 0,
      payment_frequency: "",
      price: {
        stripe_price_id: "",
        amount: 0
      }
    },
    status: "",
    future_subscription: null
  },
  plans: [],
  paymentFrequency: "",
  analysisAvailable: 0,
  tickerRequestsAvailable: 0
};

export const getSubscriptionAndPlans = createAsyncThunk(
  "subscription/getSubscriptionAndPlans",
  async (state, { rejectWithValue }) => {
    try {
      return (await authorizedRequest.get(
        "/api/users/customer/subscription/"
      )) as {
        subscription: ISubscription;
        plans: IPlan[];
        payment_frequency: string;
      };
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

const subscriptionsSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: {
    [getSubscriptionAndPlans.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getSubscriptionAndPlans.fulfilled.toString()]: (
      state,
      action: PayloadAction<{
        subscription: ISubscription;
        plans: IPlan[];
        analysis_available: number;
        stock_symbol_requests: number;
        payment_frequency: string;
      }>
    ) => {
      state.loading = false;
      state.subscription = action.payload.subscription;
      state.plans = action.payload.plans;
      state.analysisAvailable = action.payload.analysis_available;
      state.tickerRequestsAvailable = action.payload.stock_symbol_requests;
      state.paymentFrequency = action.payload.payment_frequency;
    },
    [getSubscriptionAndPlans.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export default subscriptionsSlice.reducer;
